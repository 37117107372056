import React from 'react';
import { Box } from 'grommet';
import { graphql, Link } from 'gatsby';
import { L2Hero, LibraryCard, ResponsiveContext } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout, { mainPadding } from '../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allNewsletter_indexs {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            hero_image
          }
        }
      }
      newsletters: allNewsletters {
        edges {
          node {
            _meta {
              uid
            }
            title
            image
            summary
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const newsletters = data.prismic.newsletters.edges.map((item) => {
    return {
      id: item.id,
      category: '',
      to: `/newsletters/${item.node._meta.uid}`,
      excerpt: item.node.summary[0].text || '',
      image: item.node.image.thumbnail || { alt: '', url: '' },
      title: item.node.title[0].text || '',
      linkText: 'See the latest',
    };
  });

  return (
    <Layout title={content.meta_title} description={content.meta_description}>
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <>
              <L2Hero
                backgroundURL={content.hero_image.url}
                htmlSerializer={htmlSerializer}
                size={size}
                titleHead={content.hero_header}
                titleText={content.hero_copy}
              />
              <Box
                direction="row-responsive"
                pad={mainPadding(size)}
                width={{ max: '1440px' }}
                margin={{ vertical: '0', horizontal: 'auto' }}
              >
                {newsletters &&
                  newsletters.map((item) => (
                    <>
                      <LibraryCard
                        Link={Link}
                        key={item.id}
                        library={item}
                        margin={{ bottom: 'large' }}
                      />
                    </>
                  ))}
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
